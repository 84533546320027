import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import SEO, { getSeoProps } from "../../components/Common/SEO"
import getBannerProps from "../../components/Hero/getHeroBannerProps"
import HeroForProducers from "../../components/Hero/HeroForProducers"
import { useEffect } from "react"
import { Redirect } from "@reach/router"

const ForProducerTemplate = () => {
  useEffect(() => {
    window.location.replace("https://gartnerhallen.briskebywebshop.no/")
  }, [])

  return (
    <>
      <main></main>
      {/* <RelatedSection {...relatedProps} /> */}
    </>
  )
}

export default ForProducerTemplate

export const topicQuery = graphql`
  {
    contentfulGeneric(node_locale: { eq: "nb-NO" }, slug: { eq: "briskebywebshop" }) {
      title
      id
      contentful_id
      metadataDescription
      slug
      node_locale
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
          }
        }
        url
        secure_url
        width
        height
        format
      }
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
  }
`
